@import '~bootstrap/scss/bootstrap';

.contentContainerOuter {
  overflow: auto;
  width: 100%;
  flex-grow: 1;
}

.contentContainer {
  margin: 0 auto;
  padding: 20px 0;
  width: 50%;
  min-width: 400px;
}

@include media-breakpoint-down(sm) {
  .contentContainer {
    width: 100%;
    margin: 0 auto;
    padding: 0 10px 20px 10px;
    min-width: 0;
  }
}
