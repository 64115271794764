@import '~bootstrap/scss/bootstrap';

.sidebar {
  min-width: 300px;
  width: 30vw;
  max-width: 600px;
}

@include media-breakpoint-down(md) {
  .sidebar {
    width: 60vw;
  }
}

@include media-breakpoint-down(sm) {
  .sidebar {
    width: 85vw;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 100000;
    transition: transform 0.5s ease-in-out;
  }
}

.backdrop {
  display: none;
}

@include media-breakpoint-down(sm) {
  .backdrop {
    display: flex;
    width: 15vw;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    background: #6c757d;
    opacity: 0.5;
  }
}
