@import '~bootstrap-icons/font/bootstrap-icons';

.cursor-pointer {
  cursor: pointer;
}

.loading-container {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 2000000;
  background: rgba(57, 57, 57, 0.7);
}

.loading-spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.default-avatar {
  display: inline-block;
  width: 25px;
  height: 25px;
  text-align: center;

  &.large {
    width: 32px;
    height: 32px;
  }

  &.x-large {
    width: 50px;
    height: 50px;
  }
}

.default-avatar > i {
  position: relative;
  top: calc(50% - 13px);
}
