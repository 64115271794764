.inputContainer {
  width: 100%;
}

.downshiftMenu {
  position: absolute;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  transition: opacity 0.1s ease;
}
